import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { TActivityElementCategoryValue } from 'activities/pages/types/activity.type';
import { Button, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { toActivityValueDisplay } from './ActivitiesTable.utils';
import TEObjectManager from 'activities/services/TEObjects.service';

export default function ActivityCategoriesValue({
  activityValueCategories,
}: {
  activityValueCategories: TActivityElementCategoryValue[];
}) {
  const [activeCategoryIndex, setActivityCategoryIndex] = useState<number>(0);
  const allKeys = useMemo(() => {
    return activityValueCategories.map(({ id }) => TEObjectManager.getFieldLabel(id));
  }, [activityValueCategories, TEObjectManager.loadingFields]);

  return (
    <div className="te-flex te-items-center">
      <div className="te-flex te-items-center">
        <Button
          type="link"
          onClick={() => setActivityCategoryIndex(Math.max(activeCategoryIndex - 1, 0))}
          disabled={activeCategoryIndex === 0}
        >
          <CaretLeftOutlined />
        </Button>
        {1 + activeCategoryIndex} / {allKeys.length}
        <Button
          type="link"
          onClick={() => setActivityCategoryIndex(Math.min(activeCategoryIndex + 1, allKeys.length - 1))}
          disabled={activeCategoryIndex === allKeys.length - 1}
        >
          <CaretRightOutlined />
        </Button>
      </div>
      &nbsp;
      <div className="te-flex te-flex-col">
        <Typography.Text type="secondary" style={{ lineHeight: 1 }}>
          <small>{allKeys[activeCategoryIndex] || ' '}</small>
        </Typography.Text>
        <span>{toActivityValueDisplay(activityValueCategories[activeCategoryIndex]?.values) || 'N/A'}</span>
      </div>
    </div>
  );
}
