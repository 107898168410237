import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Skeleton } from 'antd';
import { TField, TTEObject } from '@timeedit/registration-shared';
import TEObjectsService from 'activities/services/TEObjects.service';
import { compact, keyBy } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import DatasourceSelectorV3 from 'components/DatasourceSelectorV3';
import TEFormItem from '../BaseElements/TEFormItem';
import intl, { getInlineString } from 'i18n/intl';

const language = intl.messages as Record<string, string>;

export function PrimaryObjectInfo({ primaryObject }: { primaryObject?: string }) {
  const [loading, setLoading] = useState(false);
  const [objectFields, setObjectFields] = useState<Record<string, TField>>({});
  const [objectDetails, setObjectDetails] = useState<undefined | TTEObject>();
  const [objectTypeLabel, setObjectTypeLabel] = useState('');

  useEffect(() => {
    if (!primaryObject) return;
    const doGetObjectndFields = async () => {
      setLoading(true);
      const object = (await TEObjectsService.getObjects([primaryObject]))[primaryObject];
      if (!object) return;
      setObjectDetails(object);
      const foundType = Object.values(TEObjectsService.objectTypes).find(({ id }) => id === object.types[0]);
      setObjectTypeLabel(TEObjectsService.getObjectTypeLabel(foundType?.extId!));
      const fields = await TEObjectsService.getFieldsById(object.fields.map(({ fieldId }) => fieldId));
      setObjectFields(keyBy(compact(Object.values(fields)), 'id'));
      setLoading(false);
    };
    doGetObjectndFields();
  }, [primaryObject]);

  return (
    <div data-testid="PRIMARY_OBJECT_INFO_IN_DRAWER">
      <Divider orientation="left">{getInlineString('object_info', objectTypeLabel)}</Divider>
      <Skeleton loading={loading}>
        {objectDetails?.fields.slice(0, 3).map((field) => (
          <Form.Item
            key={field.fieldId}
            label={objectFields[field.fieldId]?.name}
            tooltip={objectFields[field.fieldId]?.description}
          >
            {field.values.join(', ')}
          </Form.Item>
        ))}
      </Skeleton>
    </div>
  );
}

export function ActivityInfo({
  loading,
  editable,
  totalTracks,
  activityType,
}: {
  editable?: boolean;
  loading?: boolean;
  totalTracks?: number;
  activityType?: string;
}) {
  const [type, setActivityType] = useState<undefined | TTEObject>();
  useEffect(() => {
    if (!activityType) return;
    const doGettingActivityType = async () => {
      const objectTypes = await TEObjectsService.getObjects([activityType]);
      setActivityType(objectTypes[activityType]);
    };
    doGettingActivityType();
  }, [!activityType]);

  return (
    <div data-testid="ACTIVITY_INFO_IN_DRAWER">
      <Divider orientation="left">{language.activity_info}</Divider>
      <Skeleton loading={loading}>
        <Form.Item label={language.activity_type} tooltip={language.activity_type} name={editable && 'activityType'}>
          {editable ? (
            <TEFormItem name="activityType">
              {({ value, onChange }) => (
                <DatasourceSelectorV3
                  allowMultiple={false}
                  value={value as string[]}
                  onChange={(value: string | string[]) => onChange(value)}
                  typeId={type?.types[0]}
                />
              )}
            </TEFormItem>
          ) : (
            <>{TEObjectsService.getObjectLabel(activityType!)}</>
          )}
        </Form.Item>
        <Form.Item label={language.number_of_tracks} tooltip={language.number_of_tracks}>
          {totalTracks}
        </Form.Item>
        {editable && (
          <Form.Item label=" " colon={false}>
            <Button size="small" type="dashed" icon={<PlusOutlined />}>
              {language.add_track}
            </Button>
          </Form.Item>
        )}
      </Skeleton>
    </div>
  );
}
