import React, { useEffect, useMemo, useState } from 'react';

import { InputNumber, Space } from 'antd';
import './Duration.scss';

type TDurationProps = {
  value?: number;
  name?: string;
  onChange?: (value?: number) => void;
  disabled?: boolean;
};

const toHour = (val: number = 0) => Math.floor(val / 60);
const toMinute = (val: number = 0) => val - toHour(val) * 60;

function Duration(props: TDurationProps) {
  const { value, onChange, disabled } = props;
  const [localValue, setLocalValue] = useState(value);

  const [hour, minute] = useMemo(() => {
    return [toHour(localValue), toMinute(localValue)];
  }, [localValue]);

  const formatter = (val?: number) => {
    if (!val) return '00';
    if (val < 10) return `0${val}`;
    return val.toString();
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onValueChange = (newValue: number) => {
    setLocalValue(newValue);
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const onHourChange = (hr: null | number) => {
    const newValue = (hr || 0) * 60 + minute;
    onValueChange(newValue);
  };
  const onMinuteChange = (min: null | number) => {
    const newValue = hour * 60 + (min || 0);
    onValueChange(newValue);
  };

  if (disabled) {
    return (
      <div data-testid="ELEMENT_DURATION" className="element--duration">
        {hour}:{formatter(minute)}
      </div>
    );
  }
  return (
    <Space className="element--duration" data-testid="ELEMENT_DURATION">
      <InputNumber
        min={0}
        max={59}
        size="small"
        controls={false}
        formatter={formatter}
        value={hour}
        onChange={onHourChange}
      />
      h :
      <InputNumber
        min={0}
        max={59}
        size="small"
        controls={false}
        formatter={formatter}
        value={minute}
        onChange={onMinuteChange}
      />
      min
    </Space>
  );
}
export default Duration;
