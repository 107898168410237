import { Form } from 'antd';
import React, { ReactNode, useMemo, useRef } from 'react';

type TElementValue = undefined | null | string | string[] | number | boolean;
type TTEFormItem = {
  children: ({
    value,
    onChange,
  }: {
    value?: TElementValue;
    onChange: (value: TElementValue) => void;
  }) => ReactNode | ReactNode[];

  name: string;
};
function TEFormItem(props: TTEFormItem) {
  const { children, name } = props;
  const formInstance = Form.useFormInstance();

  const value = Form.useWatch(name, formInstance);

  const formatedValue = useMemo(() => {
    try {
      if (!value) return value;
      return JSON.parse(value);
    } catch (err) {
      return value;
    }
  }, [value]);

  const fakeInput = useRef<HTMLInputElement>(null);

  const onValueChange = (newValue: TElementValue) => {
    if (formInstance) {
      formInstance.setFieldValue(name, newValue);
      const input = fakeInput.current;
      if (input) {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window?.HTMLInputElement?.prototype,
          'value',
        )?.set;
        nativeInputValueSetter?.call(input, JSON.stringify(newValue));

        // dispatch change event
        const inputEvent = new Event('change', { bubbles: true });
        input.dispatchEvent(inputEvent);
      }
    }
  };

  return (
    <div>
      {children({ value: formatedValue, onChange: onValueChange })}
      <Form.Item style={{ display: 'none' }} name={name}>
        <input readOnly ref={fakeInput} />
      </Form.Item>
    </div>
  );
}

export default TEFormItem;
