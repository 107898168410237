import GenerateStudentSet from '../study-combinations/pages/Views/GenerateStudentSet';
import ReviewPathwayView from '../study-combinations/pages/Views/ReviewPathwayView';
import SettingsPage from '../study-combinations/pages/Views/Settings';
import intl from '../i18n/intl';
import { TNavigation, TTEUser } from '@timeedit/types/lib/types';
import { BulkAllocationPage } from '../registration-allocation/pages/BulkAllocationPage';
import { DistributionConfigPage } from '../registration-allocation/pages/DistributionConfigPage';
import { DistributionRulesPage } from '../registration-allocation/pages/DistributionRulesPage';
import { RegistrationPeriodsPage } from '../registration-allocation/pages/RegistrationPeriodsPage';
import { NotificationRulesPage } from '../registration-allocation/pages/NotifcationRulesPage';
import { StudentAdjustmentPage } from '../registration-allocation/pages/StudentAdjustmentsPage';
import { RegistrationSettingsPage } from '../registration-allocation/pages/RegistrationSettingsPage';
import { RegistrationPage } from '../registration-allocation/pages/RegistrationPage';
import { CalendarOutlined, FileSearchOutlined, UserOutlined } from '@ant-design/icons';
import { getVisibility } from '../utils/authentication';
import { AllocationObjectsPage } from '@timeedit/registration-components';

import { ActivitiesOverviewPage } from '../activities/pages/ActivitiesOverviewPage';

const language = intl.messages;

export const componentMap = {
  // registration-allocation
  BulkAllocationPage,
  StudentAdjustmentPage,
  DistributionConfigPage,
  DistributionRulesPage,
  RegistrationPeriodsPage,
  NotificationRulesPage,
  RegistrationSettingsPage,

  // Study-Combinations
  GenerateStudentSet,
  ReviewPathwayView,
  SettingsPage,

  // Activities
  ActivitiesOverviewPage,
};

const paths = {
  studentAdjustment: '/allocation/student-adjustment',
  bulkAllocation: '/allocation/bulk-allocation',
  registrationPeriods: '/allocation/registration-periods',
};

const getAllocationNavigation = (authUser: TTEUser) => {
  const showAllocateNavigation = getVisibility([], { scopes: ['TE_ALLOCATE::user'] }, authUser);
  if (!showAllocateNavigation) {
    return [];
  }
  const subItems = [
    {
      visible: true,
      path: paths.bulkAllocation,
      label: 'Allocation Overview',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <FileSearchOutlined />,
      component: 'BulkAllocationPage',
    },
    {
      visible: false,
      exact: true,

      path: paths.studentAdjustment,
      // You can pass filter values here. NB: studentAdjustmentPath, nameOfStateFilterSearchParam, and jsonToStateFilterUrlParam.
      // E.g. like: `${path.studentAdjustment}?${nameOfStateFilterSearchParam}=${jsonToStateFilterUrlParam({ courseId: <<<whatever>>>>, })}`
      label: 'Student Adjustment',
      component: 'StudentAdjustmentPage',
    },
    {
      visible: true,
      exact: true,
      path: paths.studentAdjustment,
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <UserOutlined />,
      label: 'Student Adjustment',
      component: 'StudentAdjustmentPage',
    },
    {
      visible: true,
      path: paths.registrationPeriods,
      label: 'Registration Periods',
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <CalendarOutlined />,
      component: 'RegistrationPeriodsPage',
    },
  ];
  const isAllocateAdmin = getVisibility([], { scopes: ['TE_ALLOCATE::admin'] }, authUser);
  return [
    {
      path: '/allocation',
      label: 'Allocation',
      visible: true,
      redirectTo: '/allocation/bulk-allocation',
      subItems: isAllocateAdmin ? subItems : subItems.filter((nav) => nav.label !== language.registrationPeriods),
    },
  ];
};

const getActivitiesNavigation = (authUser: TTEUser) => {
  const showActivitesNavigation = getVisibility([], { scopes: ['TE_ACTIVITIES::user'] }, authUser);
  if (!showActivitesNavigation) {
    return [];
  }
  return [
    {
      path: '/activities',
      label: 'Activities',
      visible: true,
      redirectTo: '/activities/overview',
      subItems: [
        {
          label: language['activities.overview.title'] as string,
          path: '/activities/overview',
          visible: true,
          component: 'ActivitiesOverviewPage',
        },
      ],
    },
  ];
};

const getStudyCombinationsNavigation = (authUser: TTEUser) => {
  const showStudyCombinationsNavigation = getVisibility([], { scopes: ['TE_STUDY_COMBINATIONS::user'] }, authUser);
  if (!showStudyCombinationsNavigation) {
    return [];
  }
  return [
    {
      path: '/study-combinations',
      label: language.study_combinations as string,
      visible: true,
      redirectTo: '/study-combinations/review',
      subItems: [
        {
          label: language['review_study_combination.title'] as string,
          path: '/study-combinations/review',
          visible: true,
          component: 'ReviewPathwayView',
        },
        {
          label: language['generate_student_sets.title'] as string,
          path: '/study-combinations/generate',
          visible: true,
          component: 'GenerateStudentSet',
        },
      ],
    },
  ];
};

export const getNavigation = (authUser: TTEUser): TNavigation[] => {
  return [
    ...getStudyCombinationsNavigation(authUser),
    ...getAllocationNavigation(authUser),
    ...getActivitiesNavigation(authUser),
  ];
};
