import React, { useMemo, useState } from 'react';
import TableAllItemsSelection from 'components/Table/TableAllItemsSelection';
import { difference, intersection, xor } from 'lodash';

interface Props {
  tableData: any[];
  countPerAllPages?: number;
  labels: {
    allInPageAreSelected: string;
    selectAll: string;
    allInAllPageAreSelected: string;
  };
  allKeys?: string[];
}
export const useTableSelection = (props: Props) => {
  const { tableData, countPerAllPages, labels, allKeys } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<undefined | string[]>([]);

  const allKeysOnPage = useMemo(() => tableData.map(({ _id }) => _id), [tableData]);

  const isSelectingAllInAllPage = useMemo(
    () => selectedRowKeys?.length === allKeys?.length,
    [selectedRowKeys, allKeys],
  );

  const isSelectingAllInCurrentPage = useMemo(() => {
    const selectedKeysOnPage = intersection(selectedRowKeys ?? [], allKeysOnPage);
    return xor(selectedKeysOnPage, allKeysOnPage).length === 0;
  }, [allKeysOnPage, selectedRowKeys]);

  const onSelectedRowKeyChange = (selectedKeys?: string[]) => {
    // Separate keys that are on the page and keys that are not to make the 'calculation' easier
    const allKeysOnPage: string[] = tableData.map(({ _id }) => _id);
    const allOtherPageKeys = difference(selectedRowKeys, allKeysOnPage);
    const selectedKeysOnPage = intersection(selectedKeys ?? [], allKeysOnPage);

    const updatedSelectedKeys = [...allOtherPageKeys, ...selectedKeysOnPage];

    setSelectedRowKeys(updatedSelectedKeys);
  };

  return {
    Utils: {
      selectedRowKeys,
      onSelectedRowKeyChange,
      isSelectingAllInAllPage,
    },
    Component: (
      <TableAllItemsSelection
        labels={labels}
        isSelectingAllInCurrentPage={isSelectingAllInCurrentPage}
        countPerAllPages={countPerAllPages}
        countItemsInPage={tableData.length}
        isActive={(selectedRowKeys?.length ?? 0) >= (countPerAllPages ?? 0)}
        onToggle={(active) => {
          setSelectedRowKeys(active ? allKeys ?? [] : undefined);
        }}
      />
    ),
  };
};
