import React from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { ActivityInfo } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks, ActivityWeeks } from './ActivityValues';
import { useActivityGetter } from './ActivityDrawer.hook';
import { DrawerProps } from 'antd';
import intl from 'i18n/intl';

const language = intl.messages as Record<string, string>;

type TActivityDrawer = DrawerProps & {
  activityId?: string;
  editable?: boolean;
};
function ActivityDrawer(props: TActivityDrawer) {
  const { open, onClose, editable } = props;
  const { loading, activity, initialValues } = useActivityGetter({ activityId: props.activityId! });
  return (
    <ActivityDrawerWrapper
      open={open}
      onClose={onClose}
      title="Activity"
      initialValues={initialValues}
      primaryObject={activity?.metadata?.primaryObject}
      editable={editable}
    >
      <>
        <ActivityInfo
          editable={editable}
          loading={loading}
          activityType={activity?.metadata.activityType}
          totalTracks={activity?.metadata.totalTracks}
        />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activity?.values}
          track={activity?.metadata?.track}
          title={language.track_info}
          tags={activity?.tags}
        >
          <ActivityTracks track={activity?.metadata.track} />
          <ActivityWeeks
            editable={editable}
            weeks={activity && [{ startDate: activity?.metadata.startDate, endDate: activity?.metadata.endDate }]}
          />
        </ActivityValues>
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivityDrawer;
