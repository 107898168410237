import React, { useEffect } from 'react';
import { Button, Typography } from 'antd';
import intl from 'i18n/intl';
import ActivitiesTable from '../../components/Table/ActivitiesTable';
import ImportActivitiesModal from 'activities/components/Modal/ImportActivitiesModal';
import ImportReservationsModal from 'activities/components/Modal/ImportReservationsModal';
import CreateNewImportTemplate from 'activities/components/Modal/CreateNewImportTemplateModal';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamConfigFromApi, fetchReservationModes } from 'slices/integration.slice';
import { userSelector } from 'slices/auth.slice';
import { TEImportOutlined } from 'components/Icons';
import { ActivityEvents } from 'activities/pages/types/activity.type';
import { useAppFeatureFlags } from 'activities/hooks/useAppFeatureFlags';
import ActivityDateRangeFilter from 'activities/components/Filters/ActivityDateRangeFilter';

const language = intl.messages;

// eslint-disable-next-line react/function-component-definition
const ActivitiesOverviewPage = () => {
  const user = useSelector(userSelector);
  const { organizationId } = user!;
  const dispatch = useDispatch();

  const onOpenImportModal = () => {
    document.dispatchEvent(new CustomEvent(ActivityEvents.OPEN_IMPORT_ACTIVITIES_MODAL));
  };

  const onOpenCreateTemplateModal = () => {
    document.dispatchEvent(new CustomEvent(ActivityEvents.OPEN_CREATE_NEW_IMPORT_TEMPLATE_MODAL));
  };

  const onOpenImportReservationsModal = async () => {
    document.dispatchEvent(new CustomEvent(ActivityEvents.OPEN_IMPORT_RESERVATIONS_MODAL));
  };

  const { exportReservationToCsv, examFlowV3 } = useAppFeatureFlags();

  useEffect(() => {
    (async () => {
      if (examFlowV3) {
        await dispatch(fetchExamConfigFromApi());
      }
      await dispatch(fetchReservationModes()); // When doing stuff with reservation modes, the exam config must be already there. Hence, we need to await here.
    })();
  }, [organizationId, examFlowV3, dispatch]);

  const reservationImportButton = exportReservationToCsv ? (
    <Button className="te-mr-2" onClick={onOpenImportReservationsModal}>
      <DownloadOutlined /> {language['activities.export_reservations.title'] as string}
    </Button>
  ) : null;

  return (
    <div className="tabpane__wrapper te-flex te-flex-col te-flex-1">
      <div className="te-flex te-flex-col te-flex-1 te-min-h-0">
        <div className="container">
          <div className="te-flex te-justify-between te-items-center">
            <div>
              <Typography.Title level={4}>{language['activities.overview.title'] as string}</Typography.Title>
              {examFlowV3 ? (
                <ActivityDateRangeFilter />
              ) : (
                <Typography.Text>{language['activities.overview.description'] as string}</Typography.Text>
              )}
            </div>
            <div className="te-flex te-items-center">
              {reservationImportButton}
              <Button className="te-mr-2" onClick={onOpenCreateTemplateModal}>
                <DownloadOutlined /> {language['activities.create_template.title'] as string}
              </Button>
              <Button className="te-flex te-items-center" onClick={onOpenImportModal}>
                <TEImportOutlined /> {language['activities.import.title'] as string}
              </Button>
            </div>
          </div>
        </div>
        <hr className="te-mt-2" />
        <div className="te-flex te-flex-col te-flex-1 te-min-h-0">
          <ActivitiesTable />
          {organizationId && <ImportActivitiesModal organizationId={organizationId} />}
          {organizationId && <ImportReservationsModal organizationId={organizationId} />}
          <CreateNewImportTemplate />
        </div>
      </div>
    </div>
  );
};

export { ActivitiesOverviewPage };
