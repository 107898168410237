/* eslint-disable import/no-extraneous-dependencies */
import api from '../../services/api.service';
import { EActivityVisibility } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityVisibility.type';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import { TActivity2Be } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/Activity2.type';
import XLSX from 'xlsx';
import { configService } from '../../services/config.service';
import { compact } from 'lodash';
import { notification } from 'antd';
import { TTEReservationWithExtIds } from '../../types/teReservation.type';
import intl from '../../i18n/intl';
import { TImportTemplate } from 'activities/pages/types/importTemplate.type';

const language = intl.messages as Record<string, string>;

export const SHEET_NAMES = {
  information: 'Information',
  templateId: 'Template ID',
};
export const getActivities = (organizationId: string, props: Object /* Should be more tricky */) => {
  const finalQueryObject = JSON.stringify(props);
  const urlParams = new URLSearchParams({ ssp: finalQueryObject });
  const query = urlParams.toString();
  return api.post({
    successMessage: false,
    successToastTitle: '',
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities`,
    data: {
      query,
      visibility: EActivityVisibility.DRAFT,
    },
  });
};

export const deleteActivities = (organizationId: string, activitySeriesIds: string[]) => {
  return api.post({
    successToastTitle: language['activities.overview.table.message.delete_activites_in_progress.title'],
    successToastType: 'info',
    successMessage: language['activities.overview.table.message.delete_activites_in_progress.message'],
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activities/batch-operation/delete`,
    data: {
      data: activitySeriesIds,
      idKind: EActivityGroupings.ACTIVITY_SERIES,
    },
  });
};

export const publishActivities = (organizationId: string, activitySeriesIds: string[]) => {
  return api.post({
    successToastTitle: language['activities.overview.table.message.publish_activites_in_progress.title'],
    successToastType: 'info',
    successMessage: language['activities.overview.table.message.publish_activites_in_progress.message'],
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activities/batch-operation/publish`,
    data: {
      data: activitySeriesIds,
      idKind: EActivityGroupings.ACTIVITY_SERIES,
    },
  });
};

export type TImportData = {
  activityImportId: string;
  file: File;
  template: TImportTemplate;
  owner: string;
  name: string;
  description: string;
};
export const importActivities = async (organizationId: string, importData: TImportData) => {
  try {
    const { template, owner, name, description, file, activityImportId: _activityImportId } = importData;
    const newActivityImport = {
      name,
      description,
      owner,
      activityCreateTemplateId: template._id,
    };

    let activityImportId = _activityImportId;
    if (!activityImportId) {
      const activityImportResponse = await api.post({
        successMessage: false,
        successToastTitle: '',
        endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${
          organizationId
        }/activity-imports`,
        data: {
          activityImport: newActivityImport,
        },
      });
      activityImportId = activityImportResponse._id;
    }
    const formData = new FormData();

    formData.append('csv', file);
    formData.append('activityImportId', activityImportId);
    formData.append('separator', ',');

    const results = await api.post({
      headers: {
        withCredentials: false,
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': true,
      },
      successMessage: false,
      errorMessage: false,
      endpoint: `${configService().REACT_APP_NODE_CSV_URL}api/import`,
      data: formData,
    });
    return results;
  } catch (err) {
    return err;
  }
};

export const fetchActivityImportTemplate = (organizationId: string) => {
  return api.get({
    successMessage: false,
    successToastTitle: '',
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activity-create-template`,
  });
};

export const createActivityImportTemplate = (template: TImportTemplate, organizationId: string) => {
  return api.post({
    successMessage: false,
    successToastTitle: '',
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${
      organizationId
    }/activity-create-template`,
    data: {
      activityCreateTemplate: template,
    },
  });
};

export const downloadActivityImportTemplate = async (
  templateId: string,
  templateName: string,
  includeTracks: boolean,
) => {
  const separator = ';';
  const params = new URLSearchParams();
  params.append('template', templateId);
  params.append('includeTracksColumn', String(includeTracks));
  params.append('separator', separator);

  try {
    const res: string = await api.post({
      successMessage: false,
      successToastTitle: '',
      endpoint: `${configService().REACT_APP_NODE_CSV_URL}api/createTemplate`,
      data: params,
    });
    const rows = res.split('\n').map((row) => row.split(separator));

    const columnsToRemove = ['Timezone'];
    const timezoneIndexes = compact(
      rows[0].map((title, titleIdx) => {
        if (columnsToRemove.includes(title)) return titleIdx;
        return null;
      }),
    );
    const rowsWithoutTimezone = rows
      .map((cells) => cells.filter((cell, idx) => !timezoneIndexes.includes(idx)))
      .join('\n');
    const workbook = XLSX.read(rowsWithoutTimezone, { type: 'string' });
    XLSX.writeFile(workbook, `${templateName}_${templateId}.csv`);
  } catch (e) {
    console.error(e);
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: 'Error downloading template',
      description: '',
    });
  }
};

const exportReservationsWithExtIds = (reservationMode: number, startDate: number, endDate: number) => {
  return api.post({
    successMessage: false,
    endpoint: `${configService().REACT_APP_PATHWAY_SERVICE_URL}v1/reservation-export/find`,
    data: {
      startDate,
      endDate,
      reservationMode,
    },
  });
};

type TDownloadActivityImportTemplateWithReservationsArg = {
  reservationMode: number;
  startDate: number;
  endDate: number;
  activityCreateTemplateId: string;
  templateName: string;
};

export const downloadActivityImportTemplateWithReservations = async ({
  reservationMode,
  startDate,
  endDate,
  activityCreateTemplateId,
  templateName,
}: TDownloadActivityImportTemplateWithReservationsArg) => {
  try {
    const { data } = await exportReservationsWithExtIds(reservationMode, startDate, endDate);
    const reservationsWithExtIds: TTEReservationWithExtIds = data.reservations;

    const separator = ';';
    const params = new URLSearchParams();
    params.append('template', activityCreateTemplateId);
    params.append('separator', separator);
    params.append('reservations', JSON.stringify(reservationsWithExtIds));

    const res: string = await api.post({
      successMessage: false,
      endpoint: `${configService().REACT_APP_NODE_CSV_URL}api/createTemplateWithReservations`,
      data: params,
    });
    const rows = res.split('\n').map((row) => row.split(separator));

    const columnsToRemove = ['Timezone'];
    const timezoneIndexes = compact(
      rows[0].map((title, titleIdx) => {
        if (columnsToRemove.includes(title)) return titleIdx;
        return null;
      }),
    );
    const rowsWithoutTimezone = rows
      .map((cells) => cells.filter((cell, idx) => !timezoneIndexes.includes(idx)))
      .join('\n');
    const workbook = XLSX.read(rowsWithoutTimezone, { type: 'string' });
    XLSX.writeFile(workbook, `${templateName}_${activityCreateTemplateId}.csv`);

    notification.success({
      key: configService().NOTIFICATION_KEY,
      message: 'Reservation export complete',
    });
  } catch (e) {
    console.error(e);
    notification.error({
      duration: 0,
      key: configService().NOTIFICATION_KEY,
      message: 'Error downloading template',
      description: '',
    });
  }
};

export const fetchActivityImports = async (organizationId: string) => {
  return api.get({
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activity-imports`,
  });
};

export const getFormNameByIds = async (
  organizationId: string,
  activityImportIds: string[],
): Promise<{ _id: string; activityCreateTemplateName: string }[]> => {
  return api.post({
    endpoint: `${
      configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL
    }v1/organization/${organizationId}/activity-imports/get-form-name-by-ids`,
    data: {
      activityImportIds,
    },
    successMessage: false,
  });
};

export const fetchActivityImportAttemptResult = async (organizationId: string, activityImportAttemptId: string) => {
  return api.get({
    successMessage: false,
    errorMessage: false,
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activity-imports/result/${activityImportAttemptId}`,
  });
};

export const generateForm = async (
  activityCreateTemplateId: string,
  settings: { dueDate: string; formPeriod: { startDate: string; endDate: string } },
) => {
  return api.post({
    endpoint: `${configService().REACT_APP_PREFERENCES_URL}v1/forms/generate`,
    data: {
      activityCreateTemplateId,
      ...settings,
    },
  });
};

export const generateFormInstances = async (organizationId: string, activitySeriesIds: string[]) => {
  return api.post({
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/batch-operation/review`,
    data: {
      activitySeriesIds,
    },
  });
};
export const getActivityFormInstancesStatus = async (formInstanceIds: string[]) => {
  return api.get({
    endpoint: `${configService().REACT_APP_PREFERENCES_URL}v1/form-instances/status`,
    data: {
      formInstanceIds,
    },
  });
};

export const getActivityById = async (organizationId: string, activityId: string) => {
  return api.get({
    successMessage: false,
    successToastTitle: '',
    endpoint: `${configService().REACT_APP_ACTIVITY_MANAGER_SERVICE_URL}v1/organization/${organizationId}/activities/${activityId}`,
  });
};

class TEActivitiesManager {
  organizationId: string;

  activities: Record<string, any>;

  constructor() {
    this.organizationId = '';
    this.activities = {};
  }

  init(data: any) {
    this.organizationId = data.organizationId;
  }

  async getActivities({ sourceId, primaryObject }: { sourceId: string[]; primaryObject: string[] }) {
    const response = await getActivities(this.organizationId, {
      filters: {
        sourceId: {
          values: sourceId,
        },
        primaryObject: {
          values: primaryObject,
        },
      },
      limit: -1,
    });
    return response.results as TActivity2Be[];
  }
}

export default new TEActivitiesManager();
