import React, { useMemo } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { DrawerProps } from 'antd';
import { useActivitySeriesGetter } from './ActivityDrawer.hook';
import { ActivityInfo } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks, ActivityWeeks } from './ActivityValues';
import intl from 'i18n/intl';
import { compact } from 'lodash';
import { TActivityRawValue } from 'activities/pages/types/activity.type';

const language = intl.messages as Record<string, string>;

type TActivitySeriesDrawer = DrawerProps & {
  activitySeriesId?: string;
  editable?: boolean;
};
function ActivitySeriesDrawer(props: TActivitySeriesDrawer) {
  const { activitySeries, loading, activityValues, multipleValuesExtIds } = useActivitySeriesGetter({
    activitySeriesId: props.activitySeriesId,
  });

  const initialValues = useMemo(() => {
    const tmpValues: Record<string, TActivityRawValue> = {};
    [tmpValues.duration] = compact(activitySeries?.durations);
    activityValues?.forEach((item) => {
      tmpValues[item.extId] = item.value;
    });
    return tmpValues;
  }, [activityValues]);

  const { editable } = props;
  return (
    <ActivityDrawerWrapper
      open={props.open}
      onClose={props.onClose}
      title={language['activites.activity_series']}
      initialValues={initialValues}
      primaryObject={activitySeries?.primaryObject}
      editable={editable}
    >
      <>
        <ActivityInfo
          editable={editable}
          loading={loading}
          activityType={activitySeries?.activityType}
          totalTracks={activitySeries?.numberOfTracks}
        />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activityValues}
          title={language.track_info}
          multipleValuesExtIds={multipleValuesExtIds}
          tags={activitySeries?.tags}
        >
          <ActivityTracks track={activitySeries?.numberOfTracks} />
          <ActivityWeeks weeks={activitySeries?.startEndDates} editable={editable} />
        </ActivityValues>
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivitySeriesDrawer;
