import React, { ReactNode, useEffect } from 'react';
import { Button, Drawer, Space, ConfigProvider, DrawerProps, Form } from 'antd';
import './ActivityDrawerWrapper.scss';
import intl from 'i18n/intl';
import { PrimaryObjectInfo } from './ActivityDrawerComponents';
import { TActivityRawValue } from 'activities/pages/types/activity.type';

const language = intl.messages as Record<string, string>;

type TDrawerOptions = DrawerProps & {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  initialValues?: Record<string, TActivityRawValue>;
  primaryObject?: string;
  editable?: boolean;
};

function DrawerTitle({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <div className="te-flex te-flex-col">
      <span>{title}</span>
      {subtitle && <small style={{ fontSize: 12, fontWeight: 'normal' }}>{subtitle}</small>}
    </div>
  );
}

type TDrawerActions = {
  onDiscard?: () => void;
  onSave?: () => void;
};
function DrawerActions({ onDiscard, onSave }: TDrawerActions) {
  const formInstance = Form.useFormInstance();
  return (
    <Form.Item shouldUpdate>
      {() => {
        const touched = formInstance.isFieldsTouched();
        return (
          <Space>
            <Button disabled={!touched} onClick={onDiscard} size="small">
              {language.discard_changes}
            </Button>
            <Button disabled={!touched} onClick={onSave} size="small" type="primary">
              {language.save}
            </Button>
          </Space>
        );
      }}
    </Form.Item>
  );
}

function ActivityDrawerWrapper(props: TDrawerOptions) {
  const { children, subtitle, title, onClose, open, initialValues, primaryObject, editable } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <Drawer
      data-testid="ACTIVITY_DRAWER_WRAPPER"
      className="activity-details"
      open={open}
      onClose={onClose}
      destroyOnClose
      title={<DrawerTitle title={title} subtitle={subtitle} />}
      extra={editable && <DrawerActions onDiscard={() => form.resetFields()} />}
      width={480}
      maskClosable={!editable}
    >
      <ConfigProvider
        theme={{
          components: {
            Form: {
              itemMarginBottom: 4,
            },
          },
        }}
      >
        <Form
          layout="horizontal"
          labelCol={{ span: 8, offset: 0 }}
          labelAlign="left"
          labelWrap
          form={form}
          style={{ height: '100%' }}
          initialValues={initialValues}
          onValuesChange={(changedValues, values) => {
            console.log('on values changed', changedValues, values);
          }}
        >
          <PrimaryObjectInfo primaryObject={primaryObject} />
          {children}
        </Form>
      </ConfigProvider>
    </Drawer>
  );
}

export default ActivityDrawerWrapper;
